<template>
    <div class="dialog_box">
        <el-dialog title="基础信息" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false" @closed="onclosed">
            <div class="form_box">
                <div class="form_flex">
                    <div class="form_item">
                        <div class="form_title"><span>*</span>姓名:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入姓名" v-model="form.name" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>性别:</div>
                        <div class="form_input2">
                            <el-radio v-model="form.sex" label="男" disabled>男</el-radio>
                            <el-radio v-model="form.sex" label="女" disabled>女</el-radio>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>出生日期:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.birthday" type="date" placeholder="请选择出生日期"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>民族:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入民族" v-model="form.minzu" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>籍贯:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入籍贯" v-model="form.native" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>出生地:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入出生地" v-model="form.address" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title"><span>*</span>入党时间:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.rd_time" type="date" placeholder="请选择入党时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title">参加工作时间:</div>
                        <div class="form_input3">
                            <el-date-picker v-model="form.gz_time" type="date" placeholder="请选择参加工作时间"  format="yyyy-MM-dd" value-format="yyyy-MM-dd" disabled>
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title">健康状况:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入健康状况" v-model="form.jiankang" disabled>
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title">专业技术服务:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入专业技术服务" v-model="form.zyzw">
                        </div>
                    </div>
                    <div class="form_item">
                        <div class="form_title">熟悉专业有何专长:</div>
                        <div class="form_input1">
                            <input type="text" placeholder="请输入熟悉专业有何专长" v-model="form.zyzc" disabled>
                        </div>
                    </div>
                  
                </div>
                <div class="form_btns">
                    <div class="btns1" @click="handle_ends">保存</div>
                    <div class="btns2" @click="handle_close">取消</div>
                </div>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    props: {

    },
    data() {
        return {
            shows: false,
            form:{
                user_id:0,
                name:'',//姓名
                mobile:'',//手机号
                sex:'',//性别
                birthday:'',//生日
                native:'',//籍贯
                address:'',//出生地
                gz_time:'',//参加工作时间
                jiankang:'',//健康状况
                minzu:'',//民族
                rd_time:'',//入党时间
                zyzw:'',//专业技术职务
                zyzc:'',//熟悉专业有何特长
                
            },

        };
    },
    methods: {
        onclosed(){
            this.form={
                user_id:0,
                name:'',//姓名
                mobile:'',//手机号
                sex:'',//性别
                birthday:'',//生日
                native:'',//籍贯
                address:'',//出生地
                gz_time:'',//参加工作时间
                jiankang:'',//健康状况
                minzu:'',//民族
                rd_time:'',//入党时间
                zyzw:'',//专业技术职务
                zyzc:'',//熟悉专业有何特长
                
            }
        },

        //打开
        handle_open(id,infos) {
            if(infos){
                let info=JSON.parse(JSON.stringify(infos))||{}
                // this.form.fo
                for (const key in this.form) {
                   this.form[key]=info[key]
                }
            }
            this.form.user_id=id
            this.shows = true
        },
        //关闭    
        handle_close() {
            this.shows = false

        },
        //确认
        handle_ends() {
            if(!this.form.name){
                alertErr('请输入姓名')
                return
            }
            
            if(!this.form.sex){
                alertErr('请选择性别')
                return
            }
            if(!this.form.birthday){
                alertErr('请选择出生日期')
                return
            }
            if(!this.form.minzu){
                alertErr('请输入民族')
                return
            }

            if(!this.form.native){
                alertErr('请输入籍贯')
                return
            }
            if(!this.form.address){
                alertErr('请输入出生地')
                return
            }

             if(!this.form.rd_time){
                alertErr('请选择入党时间')
                return
            }
            if(!this.form.gz_time){
                alertErr('请选择参加工作时间')
                return
            }
            if(!this.form.jiankang){
                alertErr('请输入健康状况')
                return
            }

            if(!this.form.zyzw){
                alertErr('请输入专业技术服务')
                return
            }
            if(!this.form.zyzc){
                alertErr('请输入熟悉专业有何专长')
                return
            }
         
            this.loading = true
            this.$api("editUserResume", {
                ...this.form
            }, "post").then((res) => {
                this.loading = false
                if (res.code == 200) {
                    this.shows = false
                    this.$emit("handle_get_detial");

                } else {
                    alertErr(res.msg)
                }
            });
        },
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.dialog_box {
    /deep/.el-dialog {
        width: 760px;
    }

    /deep/.el-dialog__header {
        background: #F2F5FA !important;
    }

    .form_box {
       
        .form_flex{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border-bottom: 1px solid #EEEEEE ;
            padding-bottom: 23px;
            .form_item{
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .form_title{
                    width: 126px;
                    font-family: Microsoft YaHei, Microsoft YaHei;
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                    text-align: right;
                    span{
                        color: #FC5F03;
                        margin-right: 5px;
                    }

                }
                .form_input1{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    border: 1px solid #D4D4D4;
                    margin-left: 11px;
                    input{
                        width: 190px;
                        height: 40px;
                        padding: 0 10px;                      
                        font-family: Microsoft YaHei, Microsoft YaHei;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333333;

                    }
                }
                .form_input2{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input3{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
                .form_input4{
                    width: 190px;
                    height: 40px;
                    background: #FFFFFF;
                    border-radius: 2px 2px 2px 2px;
                    margin-left: 11px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .form_btns{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 18px;
            .btns1{
                width: 91px;
                height: 38px;
                background: #2373C8;
                border-radius: 4px 4px 4px 4px;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                text-align: center;
                line-height: 38px;
                cursor: pointer;
            }
            .btns2{
                width: 91px;
                height: 38px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                border: 1px solid #B8B8B8; 
                text-align: center;
                line-height: 38px;
                cursor: pointer;
                font-family: Microsoft YaHei, Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #6A6A6A;
                margin-left: 22px;
            }
        }
    }
}
</style>